import './bootstrap';
import '../css/app.css';

// Import modules...
import {computed, createApp, h} from 'vue';
import {createInertiaApp, Link as InertiaLink, usePage} from '@inertiajs/vue3';
import simpleNotifications from '@dootix/dootix-vue-tailwind/src/modules/simple_notifications';
import {dvt_clean_source, dvt_get_locale, dvt_translate} from '@dootix/dootix-vue-tailwind/src/helpers.js'
import 'remixicon/fonts/remixicon.css'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import base from './base.js'
import {ChevronRightIcon} from '@heroicons/vue/24/outline';
import SimpleBreadcrumbs from '@dootix/dootix-vue-tailwind/src/components/navigation/SimpleBreadcrumbs.vue'
import SimpleButton from '@dootix/dootix-vue-tailwind/src/components/elements/buttons/SimpleButton.vue';
import SimpleCard from '@dootix/dootix-vue-tailwind/src/components/layout/SimpleCard.vue';
import SimpleModal from '@dootix/dootix-vue-tailwind/src/components/overlays/SimpleModal.vue';
import SimpleInput from "@dootix/dootix-vue-tailwind/src/components/forms/input_groups/SimpleInput.vue";
import SimpleMultiselect from "@dootix/dootix-vue-tailwind/src/components/forms/input_groups/SimpleMultiselect.vue";
import SimpleToggle from "@dootix/dootix-vue-tailwind/src/components/forms/toggles/SimpleToggle.vue";
import SimpleRadio from "@dootix/dootix-vue-tailwind/src/components/forms/radio_groups/SimpleRadio.vue"
import SimpleTextarea from "@dootix/dootix-vue-tailwind/src/components/forms/input_groups/SimpleTextarea.vue";
import SimpleAlert from '@dootix/dootix-vue-tailwind/src/components/feedback/SimpleAlert.vue'

const el = document.getElementById('app');
const appName = computed(() => usePage().props.appName)

const themeVariables = {
    badge: {
        styles: {
            badge: {
                state: "bg-purple-100 text-purple-700 border-purple-300 rounded-full",
            },
        },
    },
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    progress: {
        color: '#4B5563'
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .mixin({ methods: { route } })
            .mixin(base)
            .mixin({
                methods: {
                    dvt_translate,
                    dvt_get_locale,
                    dvt_clean_source,
                },
            })
            .provide('themeVariables', themeVariables)
            .provide('simpleNotifications', simpleNotifications)
            .component('InertiaLink', InertiaLink)
            .component('ChevronRightIcon', ChevronRightIcon)
            .component('SimpleBreadcrumbs', SimpleBreadcrumbs)
            .component('SimpleButton', SimpleButton)
            .component('SimpleCard', SimpleCard)
            .component('SimpleModal', SimpleModal)
            .component('SimpleInput', SimpleInput)
            .component('SimpleMultiselect', SimpleMultiselect)
            .component('SimpleToggle', SimpleToggle)
            .component('SimpleTextarea', SimpleTextarea)
            .component('SimpleAlert', SimpleAlert)
            .component('SimpleRadio', SimpleRadio)
            .mount(el);
    },
})